var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoadingSearchCourse)?_c('div',{staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"container d-flex h-100"},[_c('div',{staticClass:"row justify-content-center align-self-center"},[_c('v-progress-circular',{attrs:{"color":"green","indeterminate":"","size":"80"}})],1)])]):_c('div',{staticStyle:{"height":"100vh"}},[(_vm.courses.length == 0)?_c('div',{staticClass:"container d-flex h-50 mt-5"},[_c('div',{staticClass:"no-result center-element"},[_c('span',{staticStyle:{"font-size":"1.4em"}},[_vm._v(_vm._s(_vm.message))])])]):_c('div',{staticStyle:{"max-width":"1300px","margin":"0 auto","width":"85%"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"mt-3",attrs:{"md":"12"}},[_c('span',{staticStyle:{"font-size":"1.4em"}},[_vm._v(_vm._s(_vm.message))])]),_c('v-col',{attrs:{"sm":"mr-auto"}},_vm._l((_vm.courses),function(course,index){return _c('v-card',{key:index,staticClass:"mb-2 text-decoration-none",attrs:{"elevation":"0","to":{ name: 'buy-cursos', params: { ide: course.id } }}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"auto","cols":"3"}},[_c('v-img',{staticClass:"m-2",attrs:{"src":course.url_portada,"max-width":"260px","max-height":"145px"}})],1),_c('v-col',{attrs:{"sm":"mr-auto"}},[_c('div',{staticClass:"m-1"},[_c('div',{staticClass:"font-weight-bold text-capitalize",class:[_vm.$vuetify.breakpoint.xs && 'text-mobile']},[_vm._v(" "+_vm._s(course.title)+" ")]),(!_vm.$vuetify.breakpoint.xs)?_c('div',{staticClass:"caption text-capitalize"},[_vm._v(" "+_vm._s(course.description)+" ")]):_vm._e(),_c('div',{staticClass:"text--secondary text-uppercase",class:[
                      _vm.$vuetify.breakpoint.xs ? 'text-mobile' : 'caption',
                    ]},[_vm._v(" "+_vm._s(course.name || "Administrador")+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"font-weight-bold",class:[
                        _vm.$vuetify.breakpoint.xs ? 'text-mobile' : 'subtitle-1',
                      ],staticStyle:{"color":"#b4690e"}},[_vm._v(" "+_vm._s(course.ranking_by_user)+" ")])]),(_vm.$vuetify.breakpoint.xs)?_c('div',{staticClass:"text-start d-flex"},[_c('div',{staticClass:"font-weight-bold mr-1",class:[_vm.$vuetify.breakpoint.xs && 'text-mobile']},[_vm._v(" $"+_vm._s(course.price_with_discount)+" ")]),_c('div',{staticClass:"text-decoration-line-through text--secondary",class:[
                        _vm.$vuetify.breakpoint.xs ? 'text-mobile' : 'caption',
                      ]},[_vm._v(" $299.99 ")])]):_vm._e()])]),(!_vm.$vuetify.breakpoint.xs)?_c('v-col',{attrs:{"sm":"mr-auto","cols":"2"}},[_c('div',{staticClass:"ma-2 text-end"},[_c('div',{staticClass:"font-weight-bold",class:[_vm.$vuetify.breakpoint.xs && 'text-mobile']},[_vm._v(" "+_vm._s(course.price === 0 ? "GRATIS" : `$${course.price_with_discount}`)+" ")])])]):_vm._e()],1),_c('v-divider')],1)}),1),_c('v-overlay',{attrs:{"value":_vm.overlay,"color":"white","opacity":"0.3","z-index":"9"}},[_c('v-progress-circular',{attrs:{"color":"green","indeterminate":"","size":"64"}})],1)],1),(!_vm.stateCoursesSearch)?_c('div',{staticClass:"text-center py-5"},[_c('v-pagination',{attrs:{"circle":"","length":15,"total-visible":5,"color":"green"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }